<div class="botContainer fade" [ngClass]="{ show: SHOW_CHAT, hidden: !SHOW_CHAT }">
    <!-- Toolbar -->
    <div class="toolbar" role="banner">
        <img width="40" class="iconoBot" alt="Angular Logo" [src]="LOGO_BOT" />
        <div>
            <span class="nameBot">María</span>
            <span class="online"
                ><img
                    class="bullet"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABBUlEQVQ4T2NkoBAwUqifYRAb4NXDMP3fP4aMf/8ZGJgYGRiYmBhmbCthyET3MlYvgDRLCgtkhFinMLCxsDH8+vOLYc3ROQzP337AMASrAZ5dDP+zA9IYbr3ZwfDr7w8GNmYOBjURD4apG2YxbC9DDTesBnh0MvwPdFNlePbxNsP/fwwMjEwMDFL8qgzrd91m2FFOhAFeXQz/7W0ZGBgZGeAG/P/PwHDwMAPDNmJc4FzNsEZSgSHYxJiBgYWZgeHPXwaGM2cZGJ4/YFi7t5UhBDkgcaUDOedqhj52foZgkM0gl/z8CNZcxMDA8IgYA0Bq5BgYGESRFL9G1wySG8QpkdhMRrEXAAoGShGVbz5SAAAAAElFTkSuQmCC"
                />
                Online</span
            >
        </div>
        <img src="assets/icons/menos.svg" class="close" alt="Close" (click)="showChat()" />
        <img src="assets/icons/trash.svg" [hidden]="BORRA_HISTORIAL" class="clearHistory" alt="Clear" (click)="clearHistory()" />
    </div>
    <!-- End Toolbar -->

    <div class="content scroll" #botAmy id="botAmy"></div>
    <div class="containerForm">
        <form #f="ngForm" (ngSubmit)="onSubmit(f)" novalidate>
            <input type="text" class="inputUser" [disabled]="DISABLED_INPUT" ngModel #first="ngModel" [(ngModel)]="MENSAJE" [placeholder]="PLACEHOLDER" name="message" />
            <button type="submit" class="btnSubmitUser" [disabled]="DISABLED_INPUT" [ngClass]="{ btnSubmitUserBackground: f.value.message }"></button>
        </form>
    </div>
</div>

<!-- Button ChatBot -->
<div class="etiquetaBot fade" *ngIf="!SHOW_CHAT">
    <img width="40" (click)="showChat()" alt="Angular Logo" [src]="LOGO_BOT" />
    <!-- <span class="labelChat">Iniciar chat</span> -->
</div>
<!-- End ButtonChatbot -->

<!-- Welcome message -->
<div class="welcomeMessage fade" [ngClass]="{ showMessage: SHOW_MESSAGE, hiddenMessage: !SHOW_MESSAGE }">
    <img src="assets/icons/close.svg" class="closeMessage" alt="Close" (click)="showWelcomeMessage()" />
    ¡Hola! Soy María, puedo ayudarte a buscar casa.
</div>
<!-- End Welcome Message -->

<!-- MODAL -->
<div #imageModal id="imageModal" class="modal">
    <span class="closeModal" (click)="showModal(false)" >&times;</span>
    <img class="modal-content" id="img01">
</div>