import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { iHistory } from './app.component.model';
import { eTypeMessage } from 'src/app/core/enums/message';
import { environment } from 'src/environments/environment';

import { AwsLexService } from './core/services/aws-lex.service';
import { iMessageLex } from 'src/app/core/models/sendMessage.model';
import { v4 as uuidv4 } from 'uuid';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
    /**Logo para el bot. */
    LOGO_BOT: string = '';
    /**Mostrar o no el mensaje de bienvenida. */
    SHOW_MESSAGE: boolean = true;
    /**Bandera de la primera carga para el mensaje de bienvenida. */
    private FIRST_CHARGE: boolean = true;

    // Bandera para borrar automaticamnete el historial de un chat
    private CLEAN_HISTORI_AUTO = true;
    private HOUR_TO_AUTOCLEAN = 60; //unidades en minutos

    /**Index de la imagen en el slider. */
    private SLIDE_INDEX: number = 1;
    /**Numerador del slider en página actual. */
    private NUMBER_SLIDER: number = 1;
    /**Muestra u oculta el chat. */
    SHOW_CHAT: boolean = false;
    /**Permite o no el envío de mensajes personalizados por el usuario. */
    private SEND_MESSAGE: boolean = true;
    /**Indicador para finalizar las interacciones generales. */
    private FINISH_ACTIONS: boolean = false;
    /**Cantidad de apartados con botones a eliminar. */
    private POR_ELIMINAR: number = 0;
    /**Cantidad de divs de puntos suspensivos a eliminar. */
    private POR_ELIMINAR_DOTS: number = 0;
    /**Animación de "Escribiendo...". */
    DOTS_GIF: string = '';
    /**Variable que se encargará de mostrar las variaciones del tiempo. */
    HORA: string = '';
    /** */
    MENSAJE: string = '';
    /**Placeholder para el input. */
    PLACEHOLDER: string = 'Escriba un mensaje...';
    /**Estatus del input. */
    DISABLED_INPUT: boolean = false;
    /**Arreglo que alojará el historial del usuario. */
    HISTORIAL_BOT: iHistory[] = [];
    /**Estatus para el borrado del historial. */
    BORRA_HISTORIAL: boolean = false;
    /**Objeto para el envío de la respuesta del usuario. */
    LEX_MESSAGE!: iMessageLex;

    @ViewChild('botAmy') MESSAGES: ElementRef;
    @ViewChild('imageModal',{ static: true }) IMAGE_MODAL: ElementRef;
    constructor(private renderer: Renderer2, private window: Window, private readonly AwsLexService: AwsLexService) {}

    async ngOnInit(): Promise<void> {
        this.selectLogoBot();
        setTimeout(() => {
            //this.showWelcomeMessage();
            this.autoHiddeMessaje();
        }, 2000);
        if (localStorage.getItem('Token') === null) {
            let _token: string = uuidv4();
            let _cleanToken = _token.replace('-', '').replace('-', '').replace('-', '').replace('-', '');
            localStorage.setItem('Token', _cleanToken);
        }
    }

    showModal(show: boolean, urlImage:string = ""): void {
        let modal = this.IMAGE_MODAL.nativeElement;
        if (show){
            let imgModal = modal.children[1]
            imgModal.src = urlImage
            modal.style.display = "block";
            this.SHOW_CHAT = !this.SHOW_CHAT;
        }else{
            modal.style.display = "none";
            this.SHOW_CHAT = !this.SHOW_CHAT;
        }
    }

    /**
     * Selecciona el icono de bot según el dominio.
     * @JuanUicab-Lumston
     */
    selectLogoBot(): void {
        // if (this.window.location.host === 'localhost:4201') {
        this.LOGO_BOT = 'assets/logo/bot.svg';
        // } else {
        //     this.LOGO_BOT = 'assets/logo/buscando_casa.svg';
        // }
    }

    /**
     * Limpia el historial almacenado en el localstorage.
     * @JuanUicab-Lumston
     */
    async clearHistory() {
        if (!this.BORRA_HISTORIAL) {
            // this.deleteAllCookies();
            this.BORRA_HISTORIAL = true;

            localStorage.removeItem('SessionAttributes');
            localStorage.removeItem('History');
            localStorage.removeItem('Token');
            localStorage.removeItem('DateStartChat');
            this.HISTORIAL_BOT = [];

            const childElements: any[] = this.MESSAGES.nativeElement.children;
            for (let i: number = childElements.length - 1; i >= 0; i--) {
                this.renderer.removeChild(this.MESSAGES.nativeElement, childElements[i]);
            }

            this.PLACEHOLDER = 'Seleccione una opción...';
            this.DISABLED_INPUT = true;
            this.HORA = '';
            this.createMessageGif();
            // localStorage.setItem('Token', uuidv4());
            let _token: string = uuidv4();
            let _cleanToken = _token.replace('-', '').replace('-', '').replace('-', '').replace('-', '');
            localStorage.setItem('Token', _cleanToken);
            const _response = await this.sendUserResponse('init');
            this.removePlaceholderDotsElements();
            this.createMessage(_response);
            this.BORRA_HISTORIAL = false;
            this.FINISH_ACTIONS = false;
        }
    }

    autoCleanHistory(){
        if (localStorage.getItem('DateStartChat') === null && this.CLEAN_HISTORI_AUTO){
            localStorage.setItem('DateStartChat', new Date().toISOString())
        }else{
            let dateStartChat = new Date(localStorage.getItem('DateStartChat'))
            let today = new Date()

            let numberOfMlSeconds = dateStartChat.getTime()
            let addMlSeconds = (this.HOUR_TO_AUTOCLEAN) * 60000;
            let dateStartChat_More = new Date(numberOfMlSeconds + addMlSeconds);
            
            if(today > dateStartChat_More){
                //alert("Borrando historial")
                this.clearHistory()
                return true
            }
            return false
        }
    }

    /**
     * Inicia el char con el bot.
     * @JuanUicab-Lumston
     */
    async startChat(): Promise<void> {
        this.createMessageGif();
        // Obtiene el historial almacenado.
        const _history: iHistory[] = JSON.parse(localStorage.getItem('History'));
        const _answerUser: iHistory[] = _history !== null ? _history.filter((x) => x.type === eTypeMessage.AnswerUser) : [];
        let response = _answerUser.length > 0 ? await this.sendUserResponse(_answerUser[_answerUser.length - 1].Content.message) : await this.sendUserResponse('init');
        this.removePlaceholderDotsElements();
        this.createMessage(response);
        this.POR_ELIMINAR = response.responseCard ? response.responseCard.genericAttachments.length : 0;
        this.scrollDown();
    }

    /**
     * Envía la respuesta seleccionada del usuario a Lex.
     * @param Response Respuesta seleccionada por el usuario.
     * @returns Respuesta del envío del mensaje de usuario.
     * @JuanUicab-Lumston
     */
    async sendUserResponse(Response: string): Promise<any> {
        let _resp;
        if (Response === 'init' && localStorage.getItem('SessionAttributes') === null) {
            /**Se generan los session attributes iniciales. */
            const _localAttributes: any = {
                site_origin: document.referrer,
                domain: document.referrer !== "" ? new URL(document.referrer).host : this.window.location.host,
                // site_origin: this.window.location.href,
                // domain: this.window.location.host,
                // domain: 'www.google.com',
            };
            /**Se obtienen los session attributes existentes en el local storage. */
            const _getSessionAttributes: any = localStorage.getItem('SessionAttributes');
            const _createSessionAttributes = _getSessionAttributes !== null ? JSON.parse(localStorage.getItem('SessionAttributes')) : _localAttributes;
            let stuff: { [key: string]: string } = {};
            Object.keys(_createSessionAttributes).forEach((key) => {
                stuff[`${key}`] = _createSessionAttributes[key];
            });
            /**Se genera el objeto con los parámetros necesarios. */
            this.LEX_MESSAGE = {
                bot_alias: environment.BOT_ALIAS,
                bot_name: environment.BOT_NAME,
                message: Response,
                user_id: localStorage.getItem('Token'),
                sessionAttributes: stuff,
                requestAttributes: {},
            };

            const _result = (await this.AwsLexService.sendLexData(this.LEX_MESSAGE)) as any;
            _resp = _result;

            /**Se obtienen los session attributes de respuesta. */
            const _sessionAttributes: any = _resp.sessionAttributes;
            /**Se convierten a string los session attributes actuales. */
            const _init: boolean = Response === 'init';
            const _newSessionAttributes = _init ? _sessionAttributes : JSON.parse(_sessionAttributes);
            /**Se almacenan los session attributes en el local storage. */
            localStorage.setItem('SessionAttributes', JSON.stringify(_newSessionAttributes, null, 2));
        } else {
            const _localAttributes: any = {
                site_origin: document.referrer,
                domain: document.referrer !== "" ? new URL(document.referrer).host : this.window.location.host,
                // site_origin: this.window.location.href,
                // domain: this.window.location.host,
                // domain: 'www.google.com',
            };
            const _getSessionAttributes: any = localStorage.getItem('SessionAttributes');
            const _createSessionAttributes = _getSessionAttributes !== null ? JSON.parse(localStorage.getItem('SessionAttributes')) : _localAttributes;
            let stuff: { [key: string]: string } = {};
            Object.keys(_createSessionAttributes).forEach((key) => {
                stuff[`${key}`] = _createSessionAttributes[key];
            });
            this.LEX_MESSAGE = {
                bot_alias: environment.BOT_ALIAS,
                bot_name: environment.BOT_NAME,
                message: Response,
                user_id: localStorage.getItem('Token'),
                sessionAttributes: stuff,
                requestAttributes: {},
            };
            const _result = (await this.AwsLexService.sendLexData(this.LEX_MESSAGE)) as any;
            _resp = _result;
            _resp.sessionAttributes.key_validation_show_models = _resp.sessionAttributes.key_validation_show_models ?? 'SHOW_MODELS';
            localStorage.setItem('SessionAttributes', JSON.stringify(_resp.sessionAttributes, null, 2));
        }

        return _resp;
    }

    /**
     * Crea el mensaje para mostrar al usuario.
     * @param Message Mensaje a mostrarle al usuario por parte del bot.
     * @JuanUicab-Lumston
     */
    createMessage(Response: any): void {
        this.PLACEHOLDER = 'Seleccione una opción...';
        this.createHora();
        let _div = this.renderer.createElement('div');
        if (Response.responseCard !== undefined && Response.responseCard.genericAttachments[0].imageUrl !== undefined) {
            this.showImages(_div, Response.responseCard.genericAttachments);
        }

        const _interaccionBot: iHistory = {
            type: Response.responseCard && Response.responseCard.genericAttachments[0].imageUrl ? eTypeMessage.Galery : eTypeMessage.Server,
            Content: {
                message: Response.message,
                galery: Response.responseCard ? Response.responseCard.genericAttachments : undefined,
            },
        };

        this.guardarEnHistorial(_interaccionBot);

        this.separateTextModelDescription(_div, Response.message);
        this.renderer.addClass(_div, 'question');
        let _uniqueImg = this.renderer.createElement('img');
        this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
        this.renderer.addClass(_uniqueImg, 'botMessage');
        this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
        this.renderer.appendChild(_div, _uniqueImg);
        this.renderer.appendChild(this.MESSAGES.nativeElement, _div);

        if (Response.dialogState !== 'Fulfilled') {
            if (Response.responseCard !== undefined) {
                this.SEND_MESSAGE = false;
                this.POR_ELIMINAR = Response.responseCard.genericAttachments.length;
                if (Response.responseCard.genericAttachments[0].imageUrl && Response.responseCard.genericAttachments[0].buttons !== undefined) {
                    this.createButtons(Response.responseCard.genericAttachments[0].buttons);
                } else {
                    const _buttons: any[] = [];
                    Response.responseCard.genericAttachments.forEach((_btn) => {
                        _buttons.push(..._btn.buttons);
                    });
                    this.createButtons(_buttons);
                }
            } else {
                this.SEND_MESSAGE = true;
            }
        } else {
            this.FINISH_ACTIONS = true;
            this.SEND_MESSAGE = false;
            this.scrollDown();
            return;
        }

        this.PLACEHOLDER = this.SEND_MESSAGE ? 'Escriba aquí...' : 'Seleccione una opción...';
        this.DISABLED_INPUT = !this.SEND_MESSAGE;
        this.scrollDown();
    }

    /**
     * Crea div con la hora en que fue creado el mensaje.
     * @JuanUicab-Lumston
     */
    createHora(): void {
        let _div = this.renderer.createElement('div');
        this.renderer.addClass(_div, 'hora');
        const _dateNow = new Date(Date.now());
        const _horaActual = `${_dateNow.getHours().toString().padStart(2, '0')}:${_dateNow.getMinutes().toString().padStart(2, '0')}`;
        const _hora = this.renderer.createText(_horaActual);
        if (this.HORA === '' || this.HORA !== _hora.data) {
            this.renderer.appendChild(_div, _hora);
            this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
            this.HORA = _hora.data;
        }
    }

    /**
     * Crea respuesta del gif de ejemplo para navegación.
     * @JuanUicab-Lumston
     */
    createMessageGif(): void {
        this.POR_ELIMINAR_DOTS = 1;
        this.DOTS_GIF = 'assets/icons/dots.gif';
        let _div = this.renderer.createElement('div');

        this.renderer.addClass(_div, 'question');

        let _dots = this.renderer.createElement('img');
        this.renderer.setProperty(_dots, 'src', this.DOTS_GIF);
        this.renderer.addClass(_dots, 'dotsGif');
        this.renderer.setProperty(_dots, 'loading', 'lazy');
        this.renderer.appendChild(_div, _dots);

        this.renderer.addClass(_div, 'gif');
        let _uniqueImg = this.renderer.createElement('img');
        this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
        this.renderer.addClass(_uniqueImg, 'botMessage');
        this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
        this.renderer.appendChild(_div, _uniqueImg);
        this.renderer.appendChild(this.MESSAGES.nativeElement, _div);

        this.scrollDown();
    }

    /**
     * Separa de manera coherente el texto de la respuesta de Lex.
     * @param Element Elemento al que se le agregará el texto a tratar.
     * @param Text Texto raw según respuesta del servidor.
     * @JuanUicab-Lumston
     */
    separateTextModelDescription(Element: any, Text: string, Type: string = ""): void {
        if (Type === "image"){ // Text is an url image
            let _imgElement = this.renderer.createElement('img');
            this.renderer.setProperty(_imgElement, 'src', Text);
            this.renderer.addClass(_imgElement, 'imgUrlMessage');
            this.renderer.listen(_imgElement, "click", (event)=>{
                let target = event.target || event.srcElement || event.currentTarget;
                let srcAttr = target.attributes.src;
                this.showModal(true, srcAttr.nodeValue);
            })
            this.renderer.appendChild(Element, _imgElement);
        }else if(Type === "video"){
            let _videoElement = this.renderer.createElement('iframe');
            this.renderer.setProperty(_videoElement, 'src', Text);
            this.renderer.setAttribute(_videoElement,'allowfullscreen', "true")
            this.renderer.addClass(_videoElement, 'videoIframeMessage');
            this.renderer.appendChild(Element, _videoElement);
        }else{ // Text is a text message
            let _separated = Text.split('\n');
            for (let i = 0; i < _separated.length; i++) {
                let _text = this.renderer.createText(_separated[i]);
                this.renderer.appendChild(Element, _text);
                let _p = this.renderer.createElement('br');
                this.renderer.appendChild(Element, _p);
                const _subSplit = _separated.length > i + 1 ? _separated[i + 1].split('') : [];
                if (_subSplit.length > 0 && _subSplit[0] !== ' ') {
                    let _p = this.renderer.createElement('br');
                    this.renderer.appendChild(Element, _p);
                }
            }
        }
    }


    /**
     * Elimina el contenido de botones a selección del usuario y crea un botón sin acción.
     * @param Text Texto que contiene el botón seleccionado.
     * @JuanUicab-Lumston
     */
    removePlaceholderElements(): void {
        for (let i = 0; i < this.POR_ELIMINAR; i++) {
            const placeholder1 = this.MESSAGES.nativeElement.querySelector('.text-right');
            if (placeholder1 !== null) {
                this.renderer.removeChild(this.MESSAGES.nativeElement, placeholder1);
            }
        }
    }

    /**
     * Elimina el contenido de botones a selección del usuario y crea un botón sin acción.
     * @param Text Texto que contiene el botón seleccionado.
     * @JuanUicab-Lumston
     */
    removePlaceholderDotsElements(): void {
        for (let i = 0; i < this.POR_ELIMINAR_DOTS; i++) {
            const placeholder1 = this.MESSAGES.nativeElement.querySelector('.gif');
            if (placeholder1 !== null) {
                this.renderer.removeChild(this.MESSAGES.nativeElement, placeholder1);
            }
        }
    }

    /**
     * Crea los botones de elección para los usuarios.
     * @param Text Mensaje de interacción del usuario.
     * @JuanUicab-Lumston
     */
    creaRespuestaUsuario(Text: string): void {
        let _div = this.renderer.createElement('div');
        this.renderer.addClass(_div, 'botonesRespuesta');
        const _button = this.renderer.createElement('button');
        this.renderer.addClass(_button, 'respuesta');
        const _buttonText = this.renderer.createText(Text);
        this.renderer.appendChild(_button, _buttonText);
        this.renderer.appendChild(_div, _button);
        this.renderer.appendChild(this.MESSAGES.nativeElement, _div);

        const _interaccionBot: iHistory = {
            type: eTypeMessage.User,
            Content: {
                message: Text,
            },
        };
        this.guardarEnHistorial(_interaccionBot);
    }

    /**
     * Crea los componentes y apartados necesarios para la interacción del usuario con el bot.
     * @param Answer Respuesta seleccionada por el us uario.
     * @JuanUicab-Lumston
     */
    async createComponentsAnswerUser(Text: string, Answer: string): Promise<void> {
        if (this.FINISH_ACTIONS) {
            //alert('Debe reiniciar la búsqueda para interactuar nuevamente.');
            return;
        }

        this.removePlaceholderElements();
        this.creaRespuestaUsuario(Text);
        this.createMessageGif();
        const response = await this.sendUserResponse(Answer);
        this.removePlaceholderDotsElements();
        this.createMessage(response);
    }

    /**
     * Crea un slide o muestra una imagen única.
     * @param Padre Apartado donde va a agregarse el slider.
     * @param Images Arreglo de imágenes para el slider.
     * @JuanUicab-Lumston
     */
    showImages(Padre: any, Images: any[]): void {
        let _imagesList: string[] = [];

        Images.forEach((img) => {
            _imagesList.push(img.imageUrl);
        });

        //Si la imagen es única.
        if (_imagesList.length === 1) {
            let _uniqueImg = this.renderer.createElement('img');
            this.renderer.setProperty(_uniqueImg, 'src', _imagesList[0]);
            this.renderer.addClass(_uniqueImg, 'uniqueImage');
            this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
            this.renderer.appendChild(Padre, _uniqueImg);
            let _hr = this.renderer.createElement('hr');
        } else {
            let _divSlides = this.renderer.createElement('div');
            this.renderer.addClass(_divSlides, 'w3-content');
            this.renderer.addClass(_divSlides, 'w3-display-container');
            _imagesList.forEach((_imgUrl) => {
                let _img = this.renderer.createElement('img');
                this.renderer.setProperty(_img, 'src', _imgUrl);
                this.renderer.addClass(_img, `mySlides`);
                this.renderer.addClass(_img, `mySlides${this.NUMBER_SLIDER}`);
                this.renderer.addClass(_img, 'fade');
                this.renderer.setProperty(_img, 'loading', 'lazy');
                this.renderer.appendChild(_divSlides, _img);
            });

            const _numberSlide: string = `mySlides${this.NUMBER_SLIDER}`;

            let _btnLeft = this.renderer.createElement('button');
            this.renderer.addClass(_btnLeft, 'w3-button');
            this.renderer.addClass(_btnLeft, 'w3-white');
            this.renderer.addClass(_btnLeft, 'w3-display-left');
            let _btnLeftImg = this.renderer.createElement('img');
            this.renderer.setProperty(_btnLeftImg, 'src', 'assets/icons/izquierda.svg');
            this.renderer.appendChild(_btnLeft, _btnLeftImg);
            this.renderer.listen(_btnLeft, 'click', () => {
                this.plusDivs(-1, _numberSlide);
            });

            let _btnRight = this.renderer.createElement('button');
            this.renderer.addClass(_btnRight, 'w3-button');
            this.renderer.addClass(_btnRight, 'w3-white');
            this.renderer.addClass(_btnRight, 'w3-display-right');
            let _btnRightImg = this.renderer.createElement('img');
            this.renderer.setProperty(_btnRightImg, 'src', 'assets/icons/derecha.svg');
            this.renderer.appendChild(_btnRight, _btnRightImg);
            this.renderer.listen(_btnRight, 'click', () => {
                this.plusDivs(1, _numberSlide);
            });

            this.renderer.appendChild(_divSlides, _btnLeft);
            this.renderer.appendChild(_divSlides, _btnRight);
            this.renderer.appendChild(Padre, _divSlides);
        }

        let _hr = this.renderer.createElement('hr');
        this.renderer.appendChild(Padre, _hr);
    }

    /**
     * Navega entre las imágenes del slider.
     * @param n Muestra la imagen siguiente (1) o anterior (-1);
     * @param Slider Slider sobre el que se realizará la acción.
     * @JuanUicab-Lumston
     */
    plusDivs(n: number, Slider: string): void {
        this.showDivs((this.SLIDE_INDEX += n), Slider);
    }

    /**
     * Activará el slider creado.
     * @param n Número de imagen que se mostrará.
     * @param Slider Slider en actual a trabajar.
     * @JuanUicab-Lumston
     */
    showDivs(n: number, Slider: string): void {
        let x = document.getElementsByClassName(Slider);
        if (x.length > 0) {
            if (n > x.length) {
                this.SLIDE_INDEX = 1;
            }
            if (n < 1) {
                this.SLIDE_INDEX = x.length;
            }
            for (let i: number = 0; i < x.length; i++) {
                x[i].setAttribute('style', 'display: none');
            }
            x[this.SLIDE_INDEX - 1].setAttribute('style', 'display: block');
        }
    }

    /**
     * Regresa el scroll hasta abajo de manera automática.
     * @JuanUicab-Lumston
     */
    scrollDown(): void {
        this.showDivs(1, 'mySlides' + this.NUMBER_SLIDER);
        this.NUMBER_SLIDER++;
        setTimeout(() => {
            let objDiv = document.getElementById('botAmy');
            objDiv.scrollTop = objDiv.scrollHeight;
        }, 500);
    }

    /**
     * Agrega botones a la página.
     * @param Buttons Arreglo de botones para agregar a la página.
     * @JuanUicab-Lumston
     */
    createButtons(Buttons: any[]): void {
        const _interaccionBot: iHistory = {
            type: eTypeMessage.AnswerUser,
            Content: {
                message: Buttons,
            },
        };
        this.guardarEnHistorial(_interaccionBot);

        let _div = this.renderer.createElement('div');
        this.renderer.addClass(_div, 'text-right');
        Buttons.forEach((btn) => {
            const _button = this.renderer.createElement('button');
            this.renderer.addClass(_button, 'eliminar');
            const _buttonText = this.renderer.createText(btn.text);
            this.renderer.appendChild(_button, _buttonText);
            this.renderer.appendChild(_div, _button);
            this.renderer.listen(_button, 'click', () => {
                this.createComponentsAnswerUser(`${btn.text}`, `${btn.value}`);
            });
        });
        this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
    }

    /**
     * Envía los datos del formulario actual.
     * @param f Formulario actual.
     * @JuanUicab-Lumston
     */
    async onSubmit(f: NgForm): Promise<void> {
        if(this.autoCleanHistory()){
            f.resetForm();
            return 
        }
        if (this.SEND_MESSAGE && f.value.message !== null) {
            this.DISABLED_INPUT = true;
            const _historyUser: iHistory = {
                type: eTypeMessage.User,
                Content: {
                    message: f.value.message,
                },
            };
            this.guardarEnHistorial(_historyUser);

            this.userResponse(f.value.message, {}).then(()=>{
                this.scrollDown();
            })
            this.DISABLED_INPUT = false;
        } else {
            //alert('Por ahora debe responderme con las opciones que le he brindado.');
        }
        f.resetForm();
    }

    /**
     * Obtiene la respuesta proporcionada por el cliente y el servidor para mostrarlo en el contenedor del chat.
     * @param RespuestaUser Texto proporcionado por el usuario.
     * @param RespuestaServer Respuesta devuelta por el servidor.
     * @JuanUicab-Lumston
     */
    async userResponse(RespuestaUser: string, RespuestaServer: any): Promise<void> {
        let _divContenedor = this.renderer.createElement('div');
        this.renderer.addClass(_divContenedor, 'botonesRespuesta');

        const _button = this.renderer.createElement('button');
        this.renderer.addClass(_button, 'respuesta');
        const _buttonText = this.renderer.createText(RespuestaUser);
        this.renderer.appendChild(_button, _buttonText);

        this.renderer.appendChild(_divContenedor, _button);
        this.renderer.appendChild(this.MESSAGES.nativeElement, _divContenedor);

        // PETICION AL BACK

        this.createMessageGif();

        const _localAttributes: any = {
            site_origin: document.referrer,
            domain: document.referrer !== "" ? new URL(document.referrer).host : this.window.location.host,
            // site_origin: this.window.location.href,
            // domain: this.window.location.host,
            // domain: 'www.google.com',
        };
        const _getSessionAttributes: any = localStorage.getItem('SessionAttributes');
        const _createSessionAttributes = _getSessionAttributes !== null ? JSON.parse(localStorage.getItem('SessionAttributes')) : _localAttributes;
        let stuff: { [key: string]: string } = {};
        Object.keys(_createSessionAttributes).forEach((key) => {
            stuff[`${key}`] = _createSessionAttributes[key];
        });
        this.LEX_MESSAGE = {
            bot_alias: environment.BOT_ALIAS,
            bot_name: environment.BOT_NAME,
            message: RespuestaUser,
            user_id: localStorage.getItem('Token'),
            sessionAttributes: stuff,
            requestAttributes: {},
        };

        const _result = (await this.AwsLexService.sendLexData(this.LEX_MESSAGE)) as any;
        let _response = _result;
        _response.sessionAttributes.key_validation_show_models = _response.sessionAttributes.key_validation_show_models ?? 'SHOW_MODELS';
        localStorage.setItem('SessionAttributes', JSON.stringify(_response.sessionAttributes, null, 2));

        const _historyServer: iHistory = {
            type: eTypeMessage.Server,
            Content: {
                message: _response.message,
                media: _response.media
            },
        };
        this.guardarEnHistorial(_historyServer);

        RespuestaServer = _response
        //PETICIONAL AL BACK END -->

        /**Pregunta del server */

        //Create div message
        const _buttonResp = this.renderer.createElement('div');
        this.renderer.addClass(_buttonResp, 'question');

        //Add text o element in message
        this.separateTextModelDescription(_buttonResp, RespuestaServer.message);

        //Create profile img from message bot
        let _uniqueImg = this.renderer.createElement('img');
        this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
        this.renderer.addClass(_uniqueImg, 'botMessage');
        this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
        this.renderer.appendChild(_buttonResp, _uniqueImg);
        this.renderer.appendChild(this.MESSAGES.nativeElement, _buttonResp);


        if (RespuestaServer.media){
            let media_array:any[] = RespuestaServer.media
            media_array.forEach(media_item => {
                //Create div message
                const _buttonResp_MEDIA = this.renderer.createElement('div');
                this.renderer.addClass(_buttonResp_MEDIA, 'question');
                if(media_item.type === "image" || media_item.type === "text"){
                    //Add text o element in message
                    this.separateTextModelDescription(_buttonResp_MEDIA, media_item.content, media_item.type);
                }
                else if(media_item.type === "video"){
                    let idVideo = "";
                    if(media_item.content.search(".be/") >= 0) {
                        idVideo = media_item.content.split(".be/")[1]
                    }else[
                        idVideo = media_item.content.split("watch?v=")[1]
                    ]

                    let _embed_url = "https://www.youtube.com/embed/" + idVideo
                    this.separateTextModelDescription(_buttonResp_MEDIA, _embed_url, media_item.type);
                }
                //Create profile img from message bot
                let _uniqueImg_MEDIA = this.renderer.createElement('img');
                this.renderer.setProperty(_uniqueImg_MEDIA, 'src', this.LOGO_BOT);
                this.renderer.addClass(_uniqueImg_MEDIA, 'botMessage');
                this.renderer.setProperty(_uniqueImg_MEDIA, 'loading', 'lazy');
                this.renderer.appendChild(_buttonResp_MEDIA, _uniqueImg_MEDIA);
                this.renderer.appendChild(this.MESSAGES.nativeElement, _buttonResp_MEDIA);
            });
        }

        this.removePlaceholderDotsElements();

        if (RespuestaServer.responseCard !== undefined && RespuestaServer.responseCard.genericAttachments.length > 0 && RespuestaServer.slotToElicit !== undefined) {
            this.SEND_MESSAGE = false;
            this.POR_ELIMINAR = RespuestaServer.responseCard.genericAttachments.length;
            this.createButtons(RespuestaServer.responseCard.genericAttachments[0].buttons);
        } else {
            this.SEND_MESSAGE = true;
        }
    }

    /**
     * Muestra u oculta el char al usuario.
     * @JuanUicab-Lumston
     */
    showChat(): void {
        if (this.FIRST_CHARGE) {
            const _history: iHistory[] = JSON.parse(localStorage.getItem('History'));
            if (_history !== null && _history.length > 0) {
                this.cargaHistorial();
            } else {
                this.startChat();
            }
        }
        this.FIRST_CHARGE = false;
        this.SHOW_CHAT = !this.SHOW_CHAT;
        this.SHOW_MESSAGE = false;
    }

    /**
     * Muestra el mensaje de bienvenida en la primera carga si no se ha abierto la ventana de chat.
     * @JuanUicab-Lumston
     */
    showWelcomeMessage(): void {
        if (this.FIRST_CHARGE) {
            this.SHOW_MESSAGE = !this.SHOW_MESSAGE;
            this.showChat();
        }
    }

    /**
     * Oculta automáticamente el mensaje de bienvenida después de 5 segundos.
     * @JuanUicab-Lumston
     */
    autoHiddeMessaje(): void {
        setTimeout(() => {
            this.SHOW_MESSAGE = false;
        }, 5000);
    }

    /**
     * Añade un objeto al historial en el localstorage.
     * @param Registro Objeto para ser almacenado en el historial.
     * @JuanUicab-Lumston
     */
    guardarEnHistorial(Registro: iHistory): void {
        if (localStorage.getItem('History')) {
            const _history: iHistory[] = JSON.parse(localStorage.getItem('History'));
            _history.push(Registro);
            localStorage.setItem('History', JSON.stringify(_history));
        } else {
            Registro.Fecha = new Date(Date.now());
            this.HISTORIAL_BOT.push(Registro);
            localStorage.setItem('History', JSON.stringify(this.HISTORIAL_BOT));
        }
    }

    /**
     * Carga el historial de existir en el localstorage.
     * @JuanUicab-Lumston
     */
    cargaHistorial(): void {
        if (localStorage.getItem('History')) {
            this.createHora();
            const _history: iHistory[] = JSON.parse(localStorage.getItem('History'));
            const _resta = Math.round((new Date().getTime() - new Date(_history[0].Fecha).getTime()) / (1000 * 60 * 60));
            if (_resta >= 24) {
                this.clearHistory();
                return;
            }

            for (let i: number = 0; i < _history.length; i++) {
                let _div = this.renderer.createElement('div');
                if (_history[i].type === eTypeMessage.Server) {
                    this.separateTextModelDescription(_div, _history[i].Content.message);
                    this.renderer.addClass(_div, 'question');
                    let _uniqueImg = this.renderer.createElement('img');
                    this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
                    this.renderer.addClass(_uniqueImg, 'botMessage');
                    this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
                    this.renderer.appendChild(_div, _uniqueImg);
                    this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
                    
                    //Load media items in a server response
                    if(_history[i].Content.media){
                        _history[i].Content.media.forEach(media_item => {
                            //Create div message
                            const _buttonResp_MEDIA = this.renderer.createElement('div');
                            this.renderer.addClass(_buttonResp_MEDIA, 'question');
                            if(media_item.type === "image" || media_item.type === "text"){
                                //Add img element in message
                                this.separateTextModelDescription(_buttonResp_MEDIA, media_item.content, media_item.type);
                            }
                            if(media_item.type === "video"){
                                //Add iframe element in message and calculate the embed url
                                let _embed_url = "https://www.youtube.com/embed/" + media_item.content.split("watch?v=")[1]
                                this.separateTextModelDescription(_buttonResp_MEDIA, _embed_url, media_item.type);
                            }
                            //Create profile img from message bot
                            let _uniqueImg_MEDIA = this.renderer.createElement('img');
                            this.renderer.setProperty(_uniqueImg_MEDIA, 'src', this.LOGO_BOT);
                            this.renderer.addClass(_uniqueImg_MEDIA, 'botMessage');
                            this.renderer.setProperty(_uniqueImg_MEDIA, 'loading', 'lazy');
                            this.renderer.appendChild(_buttonResp_MEDIA, _uniqueImg_MEDIA);
                            this.renderer.appendChild(this.MESSAGES.nativeElement, _buttonResp_MEDIA);
                        });
                    }

                } else if (_history[i].type === eTypeMessage.User) {
                    let _div = this.renderer.createElement('div');
                    this.renderer.addClass(_div, 'botonesRespuesta');
                    const _button = this.renderer.createElement('button');
                    this.renderer.addClass(_button, 'respuesta');
                    const _buttonText = this.renderer.createText(_history[i].Content.message);
                    this.renderer.appendChild(_button, _buttonText);
                    this.renderer.appendChild(_div, _button);
                    this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
                } else if (_history[i].type === eTypeMessage.Galery) {
                    this.showImages(_div, _history[i].Content.galery);
                    this.separateTextModelDescription(_div, _history[i].Content.message);
                    this.renderer.addClass(_div, 'question');
                    let _uniqueImg = this.renderer.createElement('img');
                    this.renderer.setProperty(_uniqueImg, 'src', this.LOGO_BOT);
                    this.renderer.addClass(_uniqueImg, 'botMessage');
                    this.renderer.setProperty(_uniqueImg, 'loading', 'lazy');
                    this.renderer.appendChild(_div, _uniqueImg);
                    this.renderer.appendChild(this.MESSAGES.nativeElement, _div);
                    this.scrollDown();
                }

                if (_history.length - 1 === i) {
                    const _buttons = _history[i].Content.message as any[];
                    if (Array.isArray(_buttons)) {
                        this.POR_ELIMINAR = _buttons.length;
                        this.createButtons(_history[i].Content.message);
                    } else {
                        this.FINISH_ACTIONS = false;
                        this.SEND_MESSAGE = true;
                    }
                }
            }
        }

        this.PLACEHOLDER = this.SEND_MESSAGE ? 'Escriba aquí...' : 'Seleccione una opción...';
        this.DISABLED_INPUT = !this.SEND_MESSAGE;
        this.scrollDown();
    }

    // deleteAllCookies() {
    //     const _cookies = document.cookie.split(';');

    //     for (var i = 0; i < _cookies.length; i++) {
    //         var cookie = _cookies[i];
    //         var eqPos = cookie.indexOf('=');
    //         var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    //         document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    //     }
    // }
}
