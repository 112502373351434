// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_LEX: '2NtxQn3I3Y4nPEf61rlQd34KO6JCoYy43uWphO2s',
  URL: 'https://26j2qikx2zu65ptoz4nh74bjym0yiebq.lambda-url.us-east-1.on.aws/',
  BOT_ALIAS: 'dev',
  BOT_NAME: 'GPChatbotDev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
