import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { iMessageLex } from '../models/sendMessage.model';

@Injectable({
    providedIn: 'root',
})
export class AwsLexService {
    API_LEX: string = environment.URL;

    constructor(private readonly HttpClient: HttpClient) {}

    async sendLexData(MessageLex: iMessageLex): Promise<any[]> {
        const _result = (await this.HttpClient.post(`${this.API_LEX}`, MessageLex).toPromise()) as any;
        return _result;
        // return Promise.resolve({
        //         body:{
        //             message: "Este es un mensaje del Back",
        //             sessionAttributes: {key_validation_show_models: "SHOW_MODELS" }
        //         }
        //     }
        // )as any
    }
}
