import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor() {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const isApiUrl = request.url.startsWith(environment.URL);
        // request = request.clone({
        //         setHeaders: {
        //             Authorization: `Bearer ${token}`,
        //         },
        //     });
        request = request.clone({
            setHeaders: {
                'x-api-key': environment.API_LEX,
            },
        });

        return next.handle(request);
    }
}
